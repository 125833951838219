import * as React from "react";
import { Link, HeadFC } from "gatsby";
import { CustomHead } from "../../components/shell/CustomHead";
import Shell from "../../components/shell/Shell";

const ContactUsSuccessPage = () => {
  return (
    <Shell>
      <div className="flex min-h-full flex-col bg-white">
        <div className="flex flex-grow flex-col">
          <main className="flex flex-grow flex-col bg-white">
            <div className="mx-auto flex w-full max-w-7xl flex-grow flex-col px-4 sm:px-6 lg:px-8">
              <div className="flex-shrink-0 pt-10 sm:pt-16"></div>
              <div className="my-auto flex-shrink-0 py-16 sm:py-32">
                <p className="text-base font-semibold text-green-600">201</p>
                <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                  Success
                </h1>
                <p className="mt-2 text-base text-gray-500">
                  Your form from the "Contact Us" page has been successfully
                  submitted.
                </p>
                <div className="mt-6">
                  <Link
                    to="/"
                    className="text-base font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Go back home
                    <span aria-hidden="true"> &rarr;</span>
                  </Link>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </Shell>
  );
};

export default ContactUsSuccessPage;

export const Head: HeadFC = () => (
  <CustomHead title="Success - Contact Us Form Submission" />
);
